import { createAction, props } from '@ngrx/store';
import { PokerSession } from '../../models';

export const connect = createAction('[Session] Connect to Socket');
export const connectSuccess = createAction('[Session] Connect to Socket Success', props<{ connectionId: string }>());
export const connectFailure = createAction('[Session] Connect Socket Failure', props<{ error: any }>());

export const joinSession = createAction('[Session] Join Session');
export const joinSessionSuccess = createAction('[Session] Join Session Success', props<{ session: PokerSession }>());
export const joinSessionFailure = createAction('[Session] Join Session Failure', props<{ error: any }>());

export const updateCard = createAction('[Session] Update my card', props<{ cardValue: string }>());
export const toggleCards = createAction('[Session] Toggle card visibility');
export const resetCards = createAction('[Session] Reset cards');

export const updateSessionState = createAction('[Session] Update Session State', props<{ session: PokerSession }>());
