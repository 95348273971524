<div class="side-nav__items">
  <!-- Dashboard -->
  <!-- <a class="side-nav__item" [routerLink]="'/'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <mat-icon>home</mat-icon>
    <span>Home</span>
  </a> -->
  <a
    class="side-nav__item"
    *ngIf="user.roles.indexOf('external') < 0"
    [routerLink]="['/', 'sprints', 'current']"
    [routerLinkActive]="'active'"
  >
    <mat-icon>whatshot</mat-icon>
    <span>Current</span>
  </a>

  <a class="side-nav__item" *ngIf="user.roles.indexOf('external') < 0" [routerLink]="['/', 'backlog']" [routerLinkActive]="'active'">
    <mat-icon>receipt</mat-icon>
    <span>Backlog</span>
  </a>

  <a class="side-nav__item" [routerLink]="['/', 'projects']" [routerLinkActive]="'active'">
    <mat-icon>emoji_objects</mat-icon>
    <span>Projects</span>
  </a>

  <a class="side-nav__item" *ngIf="user.roles.indexOf('external') < 0" href="https://foster.freshdesk.com/a/tickets" target="_blank">
    <mat-icon>bug_report</mat-icon>
    <span>KBM</span>
  </a>

  <a class="side-nav__item" *ngIf="user.roles.indexOf('external') < 0" [routerLink]="['/', 'poker']" [routerLinkActive]="'active'">
    <mat-icon>casino</mat-icon>
    <span>Poker</span>
  </a>

  <a class="side-nav__item" *ngIf="user.roles.indexOf('external') < 0" [routerLink]="['/', 'users']" [routerLinkActive]="'active'">
    <mat-icon>people</mat-icon>
    <span>Users</span>
  </a>

  <span class="spacer"></span>
  <a class="side-nav__item" [routerLink]="['/', 'auth', 'signout']">
    <mat-icon>power_settings_new</mat-icon>
    <span>Sign out</span>
  </a>
</div>
