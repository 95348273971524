import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup = this.fb.group({});
  saving = false;
  saved = false;
  error = false;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private router: Router,
    ) { }

  ngOnInit() {
    this.form = this.createGroup();
  }

  createGroup() {
    const group = this.fb.group({});

    const name = this.fb.control(null);
    const username = this.fb.control(null);
    const email = this.fb.control(null);
    const password = this.fb.control(null);

    name.setValidators(Validators.required);
    username.setValidators(Validators.required);
    email.setValidators(Validators.required);
    password.setValidators(Validators.required);

    group.addControl('Name', name);
    group.addControl('Username', username);
    group.addControl('Email', email);
    group.addControl('Password', password);

    return group;
  }

  save() {
    this.saving = true;

    this.auth.register(this.form.value).subscribe(a => {
      this.saving = false;
      if (!a.succeeded) {
        this.saved = false;
        this.error = true;
        this.snackbar.open(a.errors[0].description, 'OK');
      } else {
        this.error = false;
        this.saved = true;
        const snackbarRef = this.snackbar.open('Gebruiker succesvol aangemaakt!', 'OK');
        this.form.reset();

        snackbarRef.onAction().subscribe(() => {
          this.router.navigate(['/content/CmsUsers']);
        });
      }
    });
  }

  ngOnDestroy() {
    this.snackbar.dismiss();
  }

}
