<ul class="card-picker" [class.is--fixed]="fixed">
  <li class="card-picker__item" *ngFor="let v of values" [class.is--selected]="me?.cardValue === v">
    <app-poker-card
      [customClass]="'is--small' + (me?.cardValue === v ? ' is--selected' : '')"
      [value]="v"
      [show]="true"
      (click)="setValue(v)"
    ></app-poker-card>
  </li>
</ul>
