import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentPageService {

  $subtitle =  new BehaviorSubject<string>('');
  constructor() { }

  changeSubtitle(title) {
    this.$subtitle.next(title);
  }
}

