import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Sprint } from '../../models/sprint';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sprint-form',
  templateUrl: './sprint-form.component.html',
  styleUrls: ['./sprint-form.component.scss'],
})
export class SprintFormComponent implements OnInit {
  sprint: Sprint;

  sprintForm = this.fb.group({
    id: [0],
    title: ['', Validators.required],
    velocity: [0, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
  });

  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<SprintFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.sprintForm.setValue(data.sprint);
    this.sprint = data.sprint;
  }

  ngOnInit() {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if (this.sprintForm.valid) {
      this.dialogRef.close(this.sprintForm.value);
    }
  }
}
