import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-icon',
    template: `
        <span class="icon is--{{anchor}}">
            <svg class="icon-svg">
                <use [attr.xlink:href]="baseUrl + '#' + anchor"></use>
            </svg>
        </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class IconComponent {
    @Input() anchor: string;
    baseUrl = 'assets/gfx/svg-sprite.svg';
}
