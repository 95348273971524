import { Component, OnInit, Input } from '@angular/core';
import { AfterburnerUser } from '../../models/afterburnerUser';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() data: AfterburnerUser;
  @Input() size: string = 'small';
  constructor() { }

  ngOnInit() {
  }

}
