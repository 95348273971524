<div class="app">
  <div class="app__wrapper" [class.anim--in]="!loading">
    <mat-toolbar class="app__toolbar" [class.set--fixed]="navIsFixed" #topBarEl>
      <a class="app__toolbar__user" *ngIf="user && user.isAuth">
        <app-avatar [data]="user" [size]="'large'" class="app__toolbar__user__avatar"></app-avatar>
      </a>

      <h1 class="app__toolbar__logo">
        <a class="logo" [routerLink]="'/'"></a>
      </h1>

      <h2 class="app-title">
        Afterburner
        <strong>{{ currentPage }}</strong>
      </h2>

      <span class="spacer"></span>
      <!-- <mat-form-field [appearance]="'fill'">
            <mat-label>Outline form field</mat-label>
            <input matInput placeholder="Placeholder" />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field> -->
      <span class="spacer"></span>
      <!-- <button mat-button><mat-icon>more_vert</mat-icon></button> -->
    </mat-toolbar>

    <mat-sidenav-container class="main-container" *ngIf="user">
      <mat-sidenav
        class="app__sidebar"
        *ngIf="user && user.isAuth"
        autoFocus="false"
        [mode]="'side'"
        [opened]="true"
        [class.set--fixed]="sideIsFixed"
      >
        <app-side-nav [user]="user"></app-side-nav>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet class="app__router"></router-outlet>

        <app-poker-widget></app-poker-widget>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <app-loader-indicator [loading]="loading"></app-loader-indicator>
</div>
