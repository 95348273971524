import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PokerRoutingModule } from './poker-routing.module';
import { PokerTableComponent } from './containers/poker-table/poker-table.component';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { EffectsModule } from '@ngrx/effects';
import { CardPickerComponent } from './components/card-picker/card-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { PokerCardComponent } from './components/poker-card/poker-card.component';
import { PlayerBarComponent } from './components/player-bar/player-bar.component';
import { CardPileComponent } from './components/card-pile/card-pile.component';
import { AuthModule } from '../auth/auth.module';
import { PokerWidgetComponent } from './containers/poker-widget/poker-widget.component';

@NgModule({
  declarations: [PokerTableComponent, CardPickerComponent, CardPileComponent, PokerCardComponent, PlayerBarComponent, PokerWidgetComponent],
  imports: [
    CommonModule,
    PokerRoutingModule,
    MatIconModule,
    MatButtonModule,
    AuthModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [PokerWidgetComponent],
})
export class PokerModule {}
