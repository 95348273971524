import { Component, Input, OnInit } from '@angular/core';
import { Player } from '../../models';

@Component({
  selector: 'app-card-pile',
  templateUrl: './card-pile.component.html',
  styleUrls: ['./card-pile.component.scss'],
})
export class CardPileComponent implements OnInit {
  @Input() cards: Player[];
  @Input() hideUnpokered: boolean;
  @Input() showValues: boolean;

  constructor() {}

  ngOnInit(): void {}
}
