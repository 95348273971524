<div class="fixed-header-container">
</div>

<div class="content-form register-component">
    <div class="form">
        <form class="dynamic-form app-form-input" [formGroup]="form">
            <div class="app-form-input">
                <div class="form-control type--text">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Naam" formControlName="Name" required>
                    </mat-form-field>
                </div>

                <div class="form-control type--text">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Gebruikersnaam" formControlName="Username" required>
                    </mat-form-field>
                </div>

                <div class="form-control type--text">
                    <mat-form-field>
                        <input matInput type="text" placeholder="E-mailadres" formControlName="Email" required>
                    </mat-form-field>
                </div>

                <div class="form-control type--text">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Wachtwoord" formControlName="Password" required>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="content-form__fab-container">
        <button mat-raised-button [color]="(saved ? 'success' : ( error ? 'warn' : 'accent'))" (click)="save()" [disabled]="form.invalid || saving" class="content-form__fab-button mat-elevation-z6">
        <ng-container *ngIf="!saved"><mat-icon>save_alternate</mat-icon> Opslaan</ng-container>
        <ng-container *ngIf="saved"><mat-icon>done</mat-icon> Klaar</ng-container>
    </button>
    </div>
</div>
