import * as fromSession from './session.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'poker';

export interface PokerState {
  session: fromSession.State;
}

export const reducers: ActionReducerMap<PokerState> = {
  session: fromSession.reducer,
};

export const getCircleState = createFeatureSelector<PokerState>(featureKey);

export const metaReducers: MetaReducer<PokerState>[] = !environment.production ? [] : [];
