import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SprintSummary } from '../../../core/models/sprint';
import { Chart, ChartElement } from 'chart.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-velocity-graph',
  templateUrl: './velocity-graph.component.html',
  styleUrls: ['./velocity-graph.component.scss'],
})
export class VelocityGraphComponent implements OnInit {
  @Input() data: SprintSummary[];

  get average() {
    return this.data.map(a => a.pointsBurned).reduce((a, b) => a + b, 0) / this.data.length;
  }

  @ViewChild('barChart', { static: true }) private chartRef;
  chart: any;

  constructor(private router: Router) {}

  ngOnInit() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: this.data.map(a => a.title), // your labels array
        datasets: [
          {
            type: 'line',
            data: this.data.map(a => a.velocity), // your data array
            borderColor: '#CF522D',
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
            pointRadius: 3,
            lineTension: 0,
            pointBorderWidth: 3,
            pointBackgroundColor: '#ffffff',
            fill: false,
            label:'Velocity'
          },
          {
            type: 'line',
            data: new Array(this.data.length).fill(this.average), // your data array
            borderColor: '#ABC71A',
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
            pointRadius: 3,
            lineTension: 0,
            pointBorderWidth: 3,
            pointBackgroundColor: '#ffffff',
            fill: false,
            label: 'Avg. velocity'
          },
          {
            data: this.data.map(a => a.pointsBurned), // your data array
            borderColor: '#3C859A',
            borderDash: [20, 20],
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
            pointRadius: 0,
            backgroundColor: '#3C859A',
            label: 'SP'
          },
          {
            data: this.data.map(a => a.realPointsBurned), // your data array
            borderColor: '#3C859A',
            borderDash: [20, 20],
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
            pointRadius: 0,
            backgroundColor: 'rgba(60, 133, 154, 0.1)',
            borderWidth: 2,
            label: 'RSP'
          },
        ],
      },
      options: {
        onClick: this.handleChartClick.bind(this),
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            color: 'rgb(255, 99, 132)'
        }
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                borderDash: [5, 5],
                zeroLineBorderDash: [10, 10],
                drawBorder: false,
                color: 'rgba(151,151,151,0)',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 15,
              },
              gridLines: {
                borderDash: [5, 5],
                zeroLineBorderDash: [10, 10],
                drawBorder: false,
                color: 'rgba(151,151,151,0)',
              },
            },
          ],
        },
      },
    });
  }

  handleChartClick(e) {
    const activeElement: ChartElement = this.chart.getElementAtEvent(e);
    if (activeElement) {
      this.router.navigate(['/', 'sprints', this.data[activeElement[0]._index].id]);
    }
  }
}
