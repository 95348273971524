import {
  Component,
  OnInit,
  Inject,
  OnChanges,
  AfterViewChecked,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NgForm, NgModel } from '@angular/forms';
import { AppService } from '../../../core/services/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, AfterViewChecked {
  @ViewChild('passwordField', { static: true }) passwordField: MatInput;
  @ViewChild('usernameField', { static: true }) usernameField: MatInput;
  @ViewChild('authForm', { static: true }) authForm: NgForm;

  activeStep = 1;
  user: any;
  loading = false;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private snackbar: MatSnackBar,
    @Inject(DOCUMENT) private document
  ) {
    this.appService.setLayout('funnel');
  }

  ngOnInit() {
    this.document.body.classList.add('login--step-1');
    if (this.route.snapshot.data.signout) {
      this.auth.signOut().subscribe(a => {
        // console.log(a);
        document.location.href = '/';
      });
    }

    setTimeout(() => {
      this.usernameField.focus();
    }, 100);
  }

  ngAfterViewChecked() {
    this.setBodyClasses();
  }

  login(password: string) {
    this.loading = true;

    this.auth
      .login({
        username: this.user.username,
        password: password,
        isPersistent: true,
      })
      .subscribe(a => {
        if (a.isAuth) {
          const returnUrl = this.route.snapshot.queryParams.url || '/';
          document.location.href = returnUrl;
          this.loading = false;
        } else {
          this.authForm.form.get('password').setErrors({ error: true });
          this.loading = false;
        }
      });
  }

  getUser(username: string) {
    this.loading = true;

    this.auth.getAuthByUsername(username).subscribe(a => {
      // (a);
      if (a.username) {
        this.user = a;
        this.activeStep = 2;
        this.loading = false;
        setTimeout(() => {
          this.passwordField.focus();
        }, 100);
      } else {
        this.authForm.form.get('username').setErrors({ error: true });
        this.loading = false;
      }
    });
  }

  setBodyClasses() {
    const bodyClass = this.document.body.classList;
    if (this.activeStep === 1 && !bodyClass.contains('login--step-1')) {
      bodyClass.contains('login--step-2')
        ? bodyClass.replace('login--step-2', 'login--step-1')
        : bodyClass.add('login--step-1');
    } else if (this.activeStep === 2 && !bodyClass.contains('login--step-2')) {
      bodyClass.contains('login--step-1')
        ? bodyClass.replace('login--step-1', 'login--step-2')
        : bodyClass.add('login--step-2');
    }
  }
}
