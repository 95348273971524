import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, HostListener, AfterViewInit } from '@angular/core';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { AppService } from './core/services/app.service';
import { NavService } from './core/services/nav.service';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './auth/services/auth.service';
import { CurrentPageService } from './core/services/currentPage.service';
import { MatToolbar } from '@angular/material/toolbar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('topBarEl') topBarEl: MatToolbar;
  navMode = 'side';
  // $menuItems: Observable<any[]>;
  isAuth: boolean;
  user: any;
  users: any[];
  loading = true;
  currentPage = '';
  menuActive = false;
  navIsFixed = false;
  sideIsFixed = false;
  sideBarTop: number;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private service: AppService,
    private auth: AuthService,
    private router: Router,
    private current: CurrentPageService,
    private sanitation: DomSanitizer,
    matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    auth.getAuth().subscribe(u => (this.user = u));

    this.router.events.subscribe(event => {
      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
        this.loading = false;
      }

      if (isPlatformBrowser(this.platformId) && event instanceof NavigationStart) {
        this.loading = true;
      }

      this.sideBarTop = this.topBarEl._elementRef.nativeElement.getBoundingClientRect().height;
      // this.onWindowScroll();
    });

    this.current.$subtitle.subscribe(t => (this.currentPage = t));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } else {
      this.loading = false;
    }
  }

  ngAfterViewInit() {
    // this.sideBarTop = this.topBarEl._elementRef.nativeElement.getBoundingClientRect().height;
    // this.onWindowScroll();
  }

  // get sidebarStyle() {
  //   const style = `top: ${this.sideBarTop || 0}px;`;
  //   return this.sanitation.bypassSecurityTrustStyle(style);
  // }

  // isSticky(offsetIn = 150, offsetOut = 60): boolean {
  //     const number = this.document.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
  //     let fixed = false;
  //     if (number > offsetIn) {
  //       fixed = true;
  //     } else if (this.navIsFixed && number < offsetOut) {
  //       fixed = false;
  //     }
  //     return fixed;
  // }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.menuActive) {
      const number = this.document.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      if (number > 150) {
        this.navIsFixed = true;
        this.sideIsFixed = true;
      } else if (this.navIsFixed && number < 50) {
        this.navIsFixed = false;
        this.sideIsFixed = false;
      }
    }
  }
}
