import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { AfterburnerUser } from '../../core/models/afterburnerUser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  getAuth(): Observable<AfterburnerUser> {
    return this.http.get<AfterburnerUser>('/api/auth/user');
  }

  getAuthByUsername(username: string): Observable<AfterburnerUser> {
    return this.http.get<AfterburnerUser>(`/api/auth/${username}`);
  }

  login(loginObj: any) {
    return this.http.post<any>('/api/auth/login', loginObj);
  }

  signOut() {
    return this.http.post<any>('/api/auth/logout', {});
  }

  register(data: any) {
    return this.http.post<any>('/api/auth/register', data);
  }
}
