import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { AfterburnerUser } from '../../../core/models/afterburnerUser';
import { getRouterState } from '../../../store';
import { Player, PokerSession } from '../../models';
import { connect, resetCards, selectSession, toggleCards, updateCard } from '../../store';

@Component({
  selector: 'app-poker-widget',
  templateUrl: './poker-widget.component.html',
  styleUrls: ['./poker-widget.component.scss'],
})
export class PokerWidgetComponent implements OnInit {
  session$: Observable<PokerSession>;
  auth$: Observable<AfterburnerUser>;
  hidden: boolean;

  me$: Observable<Player>;

  testToggle = true;

  constructor(private store: Store, private auth: AuthService) {}

  ngOnInit(): void {
    this.auth$ = this.auth.getAuth().pipe(take(1));

    this.store.select(getRouterState).subscribe(a => {
      if (a?.state?.url?.toLowerCase() === '/poker') {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    });

    this.session$ = this.store.select(selectSession);

    this.me$ = this.session$.pipe(
      withLatestFrom(this.auth$),
      map(([s, a]) => {
        return s?.players.find(p => p.userId === a.id);
      })
    );
  }

  changeCardValue(cardValue: string) {
    this.store.dispatch(updateCard({ cardValue }));
  }

  toggleCards() {
    this.store.dispatch(toggleCards());
  }

  reset() {
    this.store.dispatch(resetCards());
  }
}
