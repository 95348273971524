<h1 mat-dialog-title>Wait!</h1>
<div mat-dialog-content>
  <p>You haven't saved your changes, yet. Are you sure you want to proceed without saving?</p>
</div>
<div mat-dialog-actions>
  <div class="button-group spacer"></div>

  <button mat-button cdkFocusInitial (click)="closeModal(false)">No, go back</button>
  <button mat-flat-button  color="primary"  (click)="closeModal(true)">Yes, proceed</button>
</div>
