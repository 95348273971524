import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, max: number = 20): any {
    if (value.length > max) {
      return value.slice(0, max) + "…";
    }

    return value;
  }
}
