import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-status-orb',
  templateUrl: './status-orb.component.html',
  styleUrls: ['./status-orb.component.scss'],
})
export class StatusOrbComponent implements OnInit {
  @Input() status: number;

  statusClasses = ['is--new', 'is--approved', 'is--doing', 'is--verify', 'is--ready', 'is--done', 'is--priority'];

  constructor() {}

  ngOnInit() {}
}
