import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { PokerSession } from '../models';
import { connectSuccess, joinSession, joinSessionSuccess, updateSessionState } from '../store/actions/session.action';

@Injectable({
  providedIn: 'root',
})
export class PokerHubService {
  private connection: signalR.HubConnection;

  constructor(private store: Store) {
    this.connection = new signalR.HubConnectionBuilder().withUrl('/hubs/poker').withAutomaticReconnect().build();
  }

  Connect() {
    if (this.connection.state !== signalR.HubConnectionState.Connected) {
      this.connection.start().catch(err => console.log(err));

      this.connection.on('connected', connectionId => {
        this.store.dispatch(connectSuccess({ connectionId }));
        this.store.dispatch(joinSession());
      });
    }
  }

  JoinSession() {
    this.connection.send('joinSession');

    this.connection.on('joinSessionSuccess', session => {
      this.store.dispatch(joinSessionSuccess({ session }));
      this.connection.off('joinSessionSuccess');

      this.connection.on('updateSessionState', (sesh: PokerSession) => {
        this.store.dispatch(updateSessionState({ session: sesh }));
      });
    });
  }

  UpdateCard(cardValue: string) {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.send('updateCard', cardValue);
    }
  }

  ToggleCards() {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.send('toggleCards');
    }
  }

  Reset() {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.send('reset');
    }
  }
}
