<h1 mat-dialog-title *ngIf="sprint?.id <= 0">New sprint</h1>
<h1 mat-dialog-title *ngIf="sprint?.id > 0">Edit {{ sprint.title }}</h1>
<div mat-dialog-content>
  <form [formGroup]="sprintForm">
    <mat-form-field appearance="outline">
      <input matInput placeholder="" formControlName="title" />
      <mat-label>Title</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input matInput placeholder="" formControlName="velocity" type="number" />
      <mat-label>Velocity</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input matInput placeholder="" formControlName="startDate" type="date" />
      <mat-label>Start date</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input matInput placeholder="" formControlName="endDate" type="date" />
      <mat-label>End date</mat-label>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="button-group">
  <div class="spacer"></div>
  <button mat-button cdkFocusInitial color="primary" (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="confirm()" [disabled]="sprintForm.invalid">Save</button>
</div>
