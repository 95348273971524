import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stat-block',
  templateUrl: './stat-block.component.html',
  styleUrls: ['./stat-block.component.scss'],
})
export class StatBlockComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() value: string;
  @Input() color: string;

  constructor() {}

  ngOnInit() {}
}
