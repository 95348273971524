import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../services/nav.service';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() $menuItems: Observable<any[]>;
  @Input() user: any;
  // @Input() kbmCount: number;

  navCompact = false;
  darkTheme = false;

  constructor(@Inject(DOCUMENT) private document) {}

  ngOnInit() {
    // check if darktheme is active
    if (this.document.body.classList.contains('theme--dark')) {
      this.darkTheme = true;
    }
  }

  toggleNavView() {
    this.navCompact = !this.navCompact;
    this.document.body.classList.toggle('nav--compact', this.navCompact);
  }

  toggleTheme() {
    this.darkTheme = !this.darkTheme;
    if (this.darkTheme) {
      this.document.body.classList.replace('theme--light', 'theme--dark');
    } else {
      this.document.body.classList.replace('theme--dark', 'theme--light');
    }
  }
}
