import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-poker-card',
  templateUrl: './poker-card.component.html',
  styleUrls: ['./poker-card.component.scss'],
})
export class PokerCardComponent implements OnInit {
  @Input() value: string | null;
  @Input() show: boolean;
  @Input() color: string;
  @Input() customClass: string;

  constructor() {}

  ngOnInit(): void {
    if (this.value === '?') {
      this.color = 'bg-blue';
    }
    if (this.value === 'Fout') {
      this.color = 'bg-red';
    }
  }
}
