/*
 *      Example use
 *      Basic Array of single type: *ngFor="let todo of todoService.todos | orderBy : '-'"
 *      Multidimensional Array Sort on single column: *ngFor="let todo of todoService.todos | orderBy : ['-status']"
 *      Multidimensional Array Sort on multiple columns: *ngFor="let todo of todoService.todos | orderBy : ['status', '-title']"
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sortByLength', pure: false})
export class SortByLengthPipe implements PipeTransform {
  public transform(arr: Object[], key: string): any {
    if (!arr || !Array.isArray(arr) || !arr.every(obj => Array.isArray(obj[key]))) {
      return arr;
    } else {
      return arr.sort((a, b) => b[key].length - a[key].length);
    }
  }
}
