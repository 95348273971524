import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, tap } from 'rxjs/operators';
import { Role } from '../enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data.roles;

    return this.authService.getAuth().pipe(
      map(a => {
        if (a.roles.some(r => roles.includes(r))) {
          return true;
        }

        if (a.roles.includes(Role.EXTERNAL)) {
          window.location.href = '/projects';
          this.router.navigate(['/projects']);
        } else {
          window.location.href = '/';
          this.router.navigate(['/']);
        }

        return false;
      })
      // map(a => a.roles.some(r => roles.includes(r))),
      // tap(a => {
      //   if (!a) {
      //     // Not authorized
      //     window.location.href = '/';
      //     this.router.navigate(['/']);
      //   }
      // })
    );
  }

  constructor(private authService: AuthService, private router: Router) {}
}
