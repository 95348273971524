import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as SessionActions from '../actions/session.action';
import { PokerHubService } from '../../services';

@Injectable()
export class SessionEffects {
  connect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.connect),
        tap(() => this.service.Connect())
      );
    },
    { dispatch: false }
  );

  joinSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.joinSession),
        tap(() => this.service.JoinSession())
      );
    },
    { dispatch: false }
  );

  updateCard$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.updateCard),
        tap(({ cardValue }) => this.service.UpdateCard(cardValue))
      );
    },
    { dispatch: false }
  );
  toggleCards$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.toggleCards),
        tap(() => this.service.ToggleCards())
      );
    },
    { dispatch: false }
  );
  resetCards$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.resetCards),
        tap(() => this.service.Reset())
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private service: PokerHubService) {}
}
