import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
  pure: false,
})
export class SumPipe implements PipeTransform {
  transform(value: any[], property: string): any {
    return value.reduce(function(a, b) {
      return a + b[property];
    }, 0);
  }
}
