import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs';
import { AfterburnerUser } from '../models/afterburnerUser';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private layout$ = new BehaviorSubject('default');

  public backUrl$ = new BehaviorSubject<string>('');
  public pageTitle$ = new BehaviorSubject<string>('');

  constructor() {}

  setLayout(value: 'default' | 'funnel' | 'story') {
    this.layout$.next(value);
  }

  resetLayout() {
    this.layout$.next('default');
  }

  getLayout() {
    return this.layout$;
  }
}
