import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogMessage } from '../../models/dialogMessage';

@Component({
  selector: 'app-dynamic-confirm',
  templateUrl: './dynamic-confirm.component.html',
  styleUrls: ['./dynamic-confirm.component.scss']
})
export class DynamicConfirmComponent implements OnInit {
  message: DialogMessage;

  constructor(
    public dialogRef: MatDialogRef<DynamicConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.message = data.message;
    }

  ngOnInit() {
  }

  closeModal(succes: boolean) {
    this.dialogRef.close(succes);
  }

}
