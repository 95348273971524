import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) { }

  openDialog(dialogType, width = '350px', data: any = null) {
    const dialogRef = this.dialog.open(dialogType, {
      width: width,
      data: data
    });

    return dialogRef.afterClosed();
  }
}
