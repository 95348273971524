import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-deactivate-dialog',
  templateUrl: './confirm-deactivate-dialog.component.html',
  styleUrls: ['./confirm-deactivate-dialog.component.scss']
})
export class ConfirmDeactivateDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeactivateDialogComponent>,
  ) { }

  ngOnInit() {
  }

  closeModal(canDeactivate: boolean) {
    this.dialogRef.close(canDeactivate);
  }
}
