import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppModule } from '../app.module';
import { StringJoinPipe } from './pipes/stringjoin.pipe';
import { UrlRewritePipe } from './pipes/urlrewrite.pipe';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { IconComponent } from './components/icon/icon.component';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SortByLengthPipe } from './pipes/sort-by-length.pipe';
import { InViewportDirective } from './directives/in-view.directive';
import { CountDirective } from './directives/count.directive';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StatBlockComponent } from './components/stat-block/stat-block.component';
import { StatusOrbComponent } from './components/status-orb/status-orb.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { ResizePipe } from './pipes/resize.pipe';
import { ConfirmDeleteDialogComponent } from './dialogs/confirm-delete/confirm-delete-dialog.component';
import { DialogService } from './services/dialog.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ConfirmDeactivateDialogComponent } from './dialogs/confirm-deactivate/confirm-deactivate-dialog.component';
import { SprintFormComponent } from './dialogs/sprint-form/sprint-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SumPipe } from './pipes/sum.pipe';
import { AvatarComponent } from './components/avatar/avatar.component';
import { VelocityGraphComponent } from './components/velocity-graph/velocity-graph.component';
import { DynamicConfirmComponent } from './dialogs/dynamic-confirm/dynamic-confirm.component';

const moduleExports = [
  StringJoinPipe,
  UrlRewritePipe,
  StripHtmlPipe,
  TruncatePipe,
  SortByPipe,
  SearchPipe,
  LoaderIndicatorComponent,
  IconComponent,
  ScrollToDirective,
  SortByLengthPipe,
  InViewportDirective,
  CountDirective,
  SideNavComponent,
  StatBlockComponent,
  StatusOrbComponent,
  ReversePipe,
  ResizePipe,
  SumPipe,
  AvatarComponent,
  VelocityGraphComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatCardModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatBadgeModule
    ],
    declarations: [
        moduleExports,
        StatusOrbComponent,
        ConfirmDeleteDialogComponent,
        ConfirmDeactivateDialogComponent,
        SprintFormComponent,
        SumPipe,
        DynamicConfirmComponent,
    ],
    exports: [moduleExports, ConfirmDeleteDialogComponent, ConfirmDeactivateDialogComponent],
    providers: [DialogService]
})
export class CoreModule {}
