<div class="loading" *ngIf="loading">

  <div class="loading__indicator type-spinner" [class.is--active]="loading">
    <span class="loading__indicator__item is--bounce1">
      <svg width="101" height="216" viewBox="0 0 101 216" xmlns="http://www.w3.org/2000/svg">
        <path d="M87.051.002H13.944a13.741 13.741 0 0 0-9.936 4.034 13.789 13.789 0 0 0-4.005 9.86v188.06a13.83 13.83 0 0 0 4.003 10.009 13.627 13.627 0 0 0 9.938 4.032 13.592 13.592 0 0 0 9.787-4.031 13.969 13.969 0 0 0 4.006-10.01v-88.12h50.416a13.51 13.51 0 0 0 9.936-4.194c5.34-5.473 5.34-14.247 0-19.72a13.77 13.77 0 0 0-9.936-4.031h-50.43V27.939H87.06c7.645-.13 13.812-6.342 13.941-14.042C100.929 6.196 94.705-.006 87.059.004L87.05.002z" fill-rule="evenodd"/>
      </svg>
    </span>

    <span class="loading__indicator__item is--bounce2">
      <svg width="108" height="185" viewBox="0 0 108 185" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.999 0C24.226.004.072 24.267 0 54.242v76.391c-.037 21.99 13.11 41.83 33.29 50.24 20.181 8.41 43.409 3.728 58.819-11.855 10.338-10.034 16.089-23.929 15.886-38.385V54.242C107.923 24.268 83.77.006 53.999 0zm26.619 130.633c-.145 14.699-12.02 26.537-26.62 26.537-14.6 0-26.476-11.838-26.62-26.537V54.242c.144-14.698 12.02-26.537 26.62-26.537 14.6 0 26.475 11.839 26.62 26.537v76.391z" fill-rule="nonzero"/>
      </svg>
    </span>

    <span class="loading__indicator__item is--bounce3">
      <svg width="93" height="159" viewBox="0 0 93 159" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.288 64.072h-.013c-4.8.104-9.43-1.78-12.8-5.208A16.795 16.795 0 0 1 28.127 46.2v-3.245a17.458 17.458 0 0 1 5.301-12.932 16.745 16.745 0 0 1 12.638-5.36h.628a17 17 0 0 1 10.524 3.52 17.917 17.917 0 0 1 6.488 8.925 11.204 11.204 0 0 0 11.524 8.194c3.92.102 7.622-1.8 9.827-5.047a11.832 11.832 0 0 0 1.787-11.121A42.277 42.277 0 0 0 71.6 8.209 40.942 40.942 0 0 0 46.718.004h-.629a40.777 40.777 0 0 0-29.97 12.523A41.887 41.887 0 0 0 3.637 42.974v2.929a41.008 41.008 0 0 0 12.6 30.245A42.788 42.788 0 0 0 46.55 88.865a21.162 21.162 0 0 1 17.224 8.924 22.069 22.069 0 0 1 3.89 19.948 21.595 21.595 0 0 1-8.136 11.94 22.122 22.122 0 0 1-13.753 4.56h-.416a21.21 21.21 0 0 1-13.513-4.644 22.178 22.178 0 0 1-7.962-11.67c-1.2-5.39-6.15-9.093-11.647-8.714a11.517 11.517 0 0 0-9.833 5.058A12.147 12.147 0 0 0 .6 125.291a46.549 46.549 0 0 0 16.597 24.119 45.472 45.472 0 0 0 28.266 9.588 46.692 46.692 0 0 0 29.848-10.457 45.2 45.2 0 0 0 16.501-27.008 46.23 46.23 0 0 0-9.562-40.051 44.64 44.64 0 0 0-35.962-17.41z" fill-rule="evenodd"/>
      </svg>
    </span>

    <span class="loading__indicator__item is--bounce4">
      <svg width="83" height="136" viewBox="0 0 83 136" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.333.002H10.755a10.646 10.646 0 0 0-7.68 3.154 10.644 10.644 0 0 0-3.073 7.59 10.436 10.436 0 0 0 3.104 7.613 10.427 10.427 0 0 0 7.65 3.214h20.037v103.599a10.59 10.59 0 0 0 3.193 7.706 10.302 10.302 0 0 0 7.56 3.12c2.85.02 5.586-1.119 7.592-3.156a10.702 10.702 0 0 0 3.069-7.67V21.566h20.126a10.12 10.12 0 0 0 7.561-3.212c2.03-2.001 3.153-4.755 3.104-7.615a10.665 10.665 0 0 0-3.104-7.612 10.297 10.297 0 0 0-7.56-3.125z" fill-rule="evenodd"/>
      </svg>
    </span>

    <span class="loading__indicator__item is--bounce5">
      <svg width="56" height="114" viewBox="0 0 56 114" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.594 18.88c5.164-.068 9.333-4.27 9.403-9.476-.04-5.206-4.239-9.405-9.403-9.403H9.404A9.307 9.307 0 0 0 0 9.404v95.115a9.386 9.386 0 0 0 2.714 6.743A9.232 9.232 0 0 0 9.404 114h37.19a9.005 9.005 0 0 0 6.615-2.733A9.434 9.434 0 0 0 56 104.561a9.434 9.434 0 0 0-2.791-6.705 9.005 9.005 0 0 0-6.612-2.735H18.729v-32.52h23.338a9.127 9.127 0 0 0 6.69-2.812c3.63-3.707 3.615-9.672-.032-13.363a9.257 9.257 0 0 0-6.658-2.706H18.729V18.857l27.865.024z" fill-rule="evenodd"/>
      </svg>
    </span>

    <span class="loading__indicator__item is--bounce6">
      <svg width="55" height="98" viewBox="0 0 55 98" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.5 44.839a24.842 24.842 0 0 0 7.379-18.116v-.38a25.284 25.284 0 0 0-7.867-18.619A26.158 26.158 0 0 0 25.06.003H8.4a8.377 8.377 0 0 0-5.96 2.392A7.923 7.923 0 0 0 .002 8.24v81.45a8.178 8.178 0 0 0 2.467 5.94 8.57 8.57 0 0 0 11.864-.058 8.126 8.126 0 0 0 2.407-5.881V53.128h11.44a9.685 9.685 0 0 1 7.106 2.903 9.199 9.199 0 0 1 2.977 6.913v26.747a8.339 8.339 0 0 0 5.148 7.668 8.382 8.382 0 0 0 9.086-1.758 8.02 8.02 0 0 0 2.503-5.91V62.955c-.002-7.07-1.704-13.423-10.498-18.116zm-9.359-18.116a9.266 9.266 0 0 1-2.992 6.992 9.662 9.662 0 0 1-7.089 2.89h-8.324V16.523h8.324a9.685 9.685 0 0 1 7.104 2.905 9.186 9.186 0 0 1 2.977 6.914v.38z" fill-rule="nonzero"/>
      </svg>
    </span>


  </div>
</div>
