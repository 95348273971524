<div class="block poker container" *ngIf="session$ | async as session">
  <app-card-pile [cards]="session.players" [showValues]="session.showCards">
    <div class="poker__actions">
      <button mat-fab mat-raised-button class="poker__toggle" color="primary" (click)="toggleCards()">
        <mat-icon *ngIf="!session.showCards">visibility</mat-icon>
        <mat-icon *ngIf="session.showCards">visibility_off</mat-icon>
      </button>

      <button mat-fab mat-raised-button class="poker__reset" color="primary" (click)="reset()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </app-card-pile>
  <app-player-bar [players]="session.players"></app-player-bar>
  <app-card-picker (valueChange)="changeCardValue($event)" [me]="me$ | async" [fixed]="true"></app-card-picker>
</div>
