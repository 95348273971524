import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.getAuth().pipe(
      first(),
      map(a => a.isAuth),
      tap(a => {
        if (!a) {
          // console.log('no auth');
          this.router.navigate([`/auth/login`], {
            queryParams: { url: state.url },
          });
        }
      })
    );
  }

  constructor(private authService: AuthService, private router: Router) {}
}
