import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Player } from '../../models';

@Component({
  selector: 'app-card-picker',
  templateUrl: './card-picker.component.html',
  styleUrls: ['./card-picker.component.scss'],
})
export class CardPickerComponent implements OnInit {
  @Input() me: Player;
  @Output() valueChange = new EventEmitter<number>();
  @Input() values = ['?', '0', '1', '2', '3', '5', '8', '13', '20', '40', '100'];
  @Input() fixed: boolean;
  constructor() {}

  ngOnInit(): void {}

  setValue(v: number) {
    this.valueChange.emit(v);
  }
}
