<ul class="user-list">
  <li class="user-list__item" *ngFor="let player of players" [class.has--value]="player.cardValue !== null">
    <figure class="user">
      <div class="user__thumb">
        <img [src]="player.avatar" alt="" />
        <mat-icon *ngIf="player.cardValue !== null">check</mat-icon>
      </div>

      <figcaption class="user__name">{{ player.firstName }}</figcaption>
    </figure>
  </li>
</ul>
