import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params, Data } from '@angular/router';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromMeta from '../reducers/meta.reducer';
import * as fromNav from '../reducers/nav.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  meta: fromMeta.MetaState;
  nav: fromNav.NavState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  meta: fromMeta.reducer,
  nav: fromNav.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');
export const getNavState = createFeatureSelector<fromNav.NavState>('nav');

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    let { params } = state;
    let { data } = state;
    const { fragment } = state;

    while (state.firstChild) {
      state = state.firstChild;
      data = {
        ...data,
        ...state.data,
      };
      params = {
        ...params,
        ...state.params,
      };
    }

    return {
      url,
      queryParams,
      params,
      data,
    };
  }
}
