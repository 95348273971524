import { Component, Input, OnInit } from '@angular/core';
import { Player } from '../../models';

@Component({
  selector: 'app-player-bar',
  templateUrl: './player-bar.component.html',
  styleUrls: ['./player-bar.component.scss'],
})
export class PlayerBarComponent implements OnInit {
  @Input() players: Player[];
  constructor() {}

  ngOnInit(): void {}
}
