import { Action, createReducer, on } from '@ngrx/store';
import { PokerSession } from '../../models';
import * as SessionActions from '../actions/session.action';

export const SessionFeatureKey = 'Session';

export interface State {
  connecting: boolean;
  connected: boolean;
  connectionId: string;
  joining: boolean;
  joined: boolean;
  session: PokerSession;
  error?: any;
}

export const initialState: State = {
  connected: false,
  connecting: false,
  joining: false,
  joined: false,
  session: null,
  connectionId: null,
};

const sessionReducer = createReducer(
  initialState,

  on(SessionActions.connect, state => {
    return {
      ...state,
      connecting: true,
      connected: false,
    };
  }),
  on(SessionActions.connectSuccess, (state, { connectionId }) => {
    return {
      ...state,
      connecting: false,
      connected: true,
      connectionId,
    };
  }),
  on(SessionActions.connectFailure, (state, { error }) => {
    return {
      ...state,
      loaded: false,
      loading: false,
      error,
    };
  }),
  on(SessionActions.joinSession, state => {
    return {
      ...state,
      joining: true,
      joined: false,
    };
  }),
  on(SessionActions.joinSessionSuccess, (state, { session }) => {
    return {
      ...state,
      session,
      joining: false,
      joined: true,
    };
  }),
  on(SessionActions.updateSessionState, (state, { session }) => {
    return {
      ...state,
      session,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return sessionReducer(state, action);
}
