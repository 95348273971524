import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resize',
})
export class ResizePipe implements PipeTransform {
  transform(
    imageUrl: string,
    width: number,
    height: number,
    anchor: 'topleft' | 'top' | 'topright' | 'left' | 'center' | 'right' | 'bottomleft' | 'bottom' | 'bottomright',
    mode: 'crop' | 'stretch' | 'pad',
    format: 'jpg' | 'png' | 'bmp' | 'gif'
  ): any {
    if (!imageUrl) {
      return imageUrl;
    }
    let val = `${imageUrl}`;
    if (width) {
      val = `${val}${this.separator(val)}w=${width}`;
    }
    if (height) {
      val = `${val}${this.separator(val)}h=${height}`;
    }
    if (anchor) {
      val = `${val}${this.separator(val)}anchor=${anchor}`;
    }
    if (mode) {
      val = `${val}${this.separator(val)}mode=${mode}`;
    }
    if (format) {
      val = `${val}${this.separator(val)}format=${format}`;
    }

    return val;
  }

  private separator(url: string) {
    return url.indexOf('?') >= 0 ? '&' : '?';
  }
}
