import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { RoleGuard } from './auth/guards/role.guard';
import { Role } from './auth/enums/role.enum';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [Role.INTERNAL],
    },
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'sprints',
    loadChildren: () => import('./sprint/sprint.module').then(m => m.SprintModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [Role.INTERNAL],
    },
  },
  {
    path: 'backlog',
    loadChildren: () => import('./backlog/backlog.module').then(m => m.BacklogModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [Role.INTERNAL],
    },
  },
  {
    path: 'poker',
    loadChildren: () => import('./poker/poker.module').then(m => m.PokerModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [Role.INTERNAL],
    },
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [Role.INTERNAL],
    },
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
