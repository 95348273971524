<div class="card-pile">
  <div class="card-pile__card" *ngFor="let card of cards">
    <div class="no-value" *ngIf="card.cardValue === null && !hideUnpokered">
      <span>?</span>
    </div>
    <div class="with-value" *ngIf="card.cardValue !== null" [class.is--flipped]="showValues">
      <app-poker-card [value]="card.cardValue" [show]="showValues"></app-poker-card>
    </div>
  </div>
  <ng-content></ng-content>
</div>
