<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="login">
    <form #authForm="ngForm" (submit)="!user ? getUser(authForm.value.username) : login(authForm.value.password)">
        <!-- STEP 1 -->
        <mat-card class="mat-elevation-z6 login__form" [hidden]="user">
            <h1 class="login__form__title">Inloggen</h1>
            <div class="login__form__field">
                <div class="form-control">
                    <mat-form-field>
                        <input type="text" #usernameField="matInput" name="username" ngModel placeholder="E-mailadres" autocomplete="username" matInput>
                        <mat-error *ngIf="usernameField.errorState">Onbekend e-mailadres</mat-error>
                    </mat-form-field>
                </div>
                <button type="submit" mat-button color="accent" [disabled]="loading">Volgende <mat-icon>arrow_forwards</mat-icon></button>
            </div>
        </mat-card>

        <!-- STEP 2 -->
        <mat-card class="mat-elevation-z6 login__form" [hidden]="!user">
            <p class="login__form__welcome-text">
                Welkom!
            </p>

            <h2 class="login__form__title">{{user?.name}}</h2>

            <div class="login__form__field">
                <div class="form-control">
                    <mat-form-field>
                        <input type="password" #passwordField="matInput" name="password" ngModel autocomplete="current-password" placeholder="Wachtwoord" matInput>
                        <mat-error *ngIf="passwordField.errorState">E-mailadres of wachtwoord onjuist</mat-error>
                    </mat-form-field>
                </div>
                <!-- <div class="form-control">
                <mat-checkbox matInput [ngModel]="false" name="isPersistent" [value]="true">Blijf ingelogd</mat-checkbox>
            </div> -->

                <button type="submit" mat-button color="accent" [disabled]="loading">Log in <mat-icon>arrow_forwards</mat-icon></button>
            </div>
        </mat-card>
    </form>
</div>
