import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AfterburnerUser } from '../../core/models/afterburnerUser';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService implements Resolve<AfterburnerUser> {
  constructor(private http: HttpClient) {}

  allUsers(): Observable<any[]> {
    return this.http.get<any[]>('/api/users');
  }

  internalUsers(): Observable<any[]> {
    return this.http.get<any[]>('/api/users/internal');
  }

  sync(): Observable<any[]> {
    return this.http.get<any[]>('/api/users/sync');
  }

  resolve(route: ActivatedRouteSnapshot): Observable<AfterburnerUser> | AfterburnerUser {
    return this.http.get<AfterburnerUser>(`/api/users/${route.paramMap.get('uid')}`);
  }
}
